import {
  redirect,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import { getUserId } from "~/utils/auth-utils/auth.server";

export const meta: MetaFunction = () => [{ title: "Ranger" }];

export default function Index() {
  return <></>;
}

export async function loader({ request }: LoaderFunctionArgs) {
  const isUserLoggedIn = await getUserId(request);

  if (request.method === "HEAD") {
    return null;
  }

  if (isUserLoggedIn) {
    return redirect("/transactions");
  } else {
    return redirect("/login");
  }

  return null;
}
